import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Blog from "./Blog/Blog";
import BlogPost from "./Blog/BlogPost";

import Home from "./Home";

function App() {
    return (
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
                <Route path="blog" >
                    <Route path=":id" element={<BlogPost />}  />
                </Route>
            </Routes>
    );
}
export default App;
