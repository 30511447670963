import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { IBlogPost } from "./BlogPost";
import BlogPostPreview from "./BlogPostPreview";



const Blog = () => {
    //fetch blog posts from API
    const [show, setShow] = useState(false);
    const [gotPosts, setGotPosts] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }
            , 1);
    })
    const [blogPosts, setBlogPosts] = useState<IBlogPost[]>([]);
    useEffect(() => {
        fetch("https://shark-app-bgi3b.ondigitalocean.app/api/blogs?populate=coverImage")
            .then(res => res.json())
            .then(data => {
                setBlogPosts(data.data);
                setGotPosts(true);
            }
        );
    }
    , []);
    
    return (
    <div className="dark:bg-black h-screen w-screen mt-0 fixed bg-white overflow-y-auto pb-20">
        <Transition show={show} as="main" appear={true} enter={'duration-1000 transition-opacity ease-in-out'} enterFrom={'opacity-0'} enterTo={'opacity-100'} className="prose max-w-2xl mx-auto mt-16 px-10   ">
            <h1 className="dark:text-white">Blog</h1>
                <div className="flex flex-col gap-10 ">
                    {
                        !blogPosts && gotPosts && <div className="text-center"> No Posts Yet :(</div>
                    }
            {
            blogPosts  &&    blogPosts.map(post => (
                    <BlogPostPreview post={post} />))
                    }
                   
            </div>
            </Transition>
        </div>
    )
    
}
export default Blog;