import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IBlogPost } from "./BlogPost";
interface IBlogPostPreviewProps {
    post: IBlogPost;
}
const BlogPostPreview: FC<IBlogPostPreviewProps> = (props) => {
    const { post } = props;
    let navigate = useNavigate();

    return (
        <div className="relative group  active:scale-[99%]  transition-transform duration-500" onClick={() => navigate(`/blog/${post.id}`)}>
            <div className="absolute  h-full w-full  bg-black dark:bg-white z-0 transition-all group-hover:top-0 group-hover:right-0 duration-300  top-2 right-2  " />
            <div className=" flex justify-between  cursor-pointer z-10 relative bg-white dark:bg-black dark:text-white  border-blue-900 px-10 py-10 border-2 ">
                <div className="flex flex-col justify-between"><div>
                    <h2 className="mt-0 text-ellipsis line-clamp-1 dark:text-white ">{post.attributes.Title}</h2>
                    <p>{post.attributes.subTitle}</p>
                </div>
                    <label className="text-neutral-300 dark:text-neutral-600 self-end">{new Date(post.attributes.PublishDate).toLocaleDateString()} - {new Date(post.attributes.publishedAt).toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit' })}</label>
                </div>
                <div >
                    <div className="relative h-full  ">
                        <div className="absolute h-full bg-black t dark:bg-whiteop-2 left-2 z-20 group-hover:inset-0  transition-all aspect-square  " />
                        <img className="mt-0 z-30 relative object-cover h-full aspect-square" src={`https://shark-app-bgi3b.ondigitalocean.app${post.attributes.coverImage.data[0].attributes.formats.thumbnail.url}`} alt={post.attributes.Title} />
                    </div>
                </div>
            </div>
        </div>)
}
export default BlogPostPreview;