import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { faGithubSquare, faInstagramSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Axel Draws</title>
            </Helmet>
            <main className="w-screen min-h-screen bg-yellow-50/20 dark:bg-black h-screen">
                <section className="flex justify-center h-[100vh] items-center">
                    <div className="self-center">
                        <h1 className="text-6xl dark:text-white">
                            Axel Draws
                        </h1>
                        <h2 className="dark:text-white">
                            Personal Site
                        </h2>
                        <span className="flex gap-1 mb-4">
                            <a  href="https://www.github.com/axdra" className="transition-color duration-500 ease-in-out hover:bg-black rounded-xl h-8 hover:h-24 w-8 hover:w-24 dark:hover:dark:bg-white">
                                <FontAwesomeIcon icon={faGithubSquare} color="#000" className="h-8 hover:h-24 w-8 hover:w-24  transition-color duration-500 ease-in-out hover:text-white dark:hover:text-black dark:text-white " />
                            </a>
                            <a  href="https://www.linkedin.com/in/axel-draws-278309166/" className="transition-color duration-500 ease-in-out hover:bg-black rounded-xl h-8 hover:h-24 w-8 hover:w-24 dark:hover:dark:bg-white">
                                <FontAwesomeIcon icon={faLinkedin} color="#000" className="h-8 hover:h-24 w-8 hover:w-24  transition-color duration-500 ease-in-out hover:text-white dark:hover:text-black dark:text-white " />
                            </a>
                            <a  href="https://www.instagram.com/axeldra/" className="transition-color duration-500 ease-in-out hover:bg-black rounded-xl h-8 hover:h-24 w-8 hover:w-24 dark:hover:dark:bg-white">
                                <FontAwesomeIcon icon={faInstagramSquare} color="#000" className="h-8 hover:h-24 w-8 hover:w-24  transition-color duration-500 ease-in-out hover:text-white dark:hover:text-black dark:text-white" />
                            </a>
                        </span>
                        <p onClick={()=>navigate('/blog')} className="dark:bg-white bg-black text-white dark:text-black py-2 px-4  cursor-pointer   select-none">Blog</p>
                    </div>
                </section>
            </main>
        </>)
}
export default Home;
