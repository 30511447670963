import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Transition } from "@headlessui/react";

export interface IBlogPost{
    id: number;
    attributes:IBlogPostAttributes;
}
export interface Thumbnail {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    path?: any;
    width: number;
    height: number;
    size: number;
    url: string;
}

export interface Medium {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    path?: any;
    width: number;
    height: number;
    size: number;
    url: string;
}

export interface Small {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    path?: any;
    width: number;
    height: number;
    size: number;
    url: string;
}

export interface Formats {
    thumbnail: Thumbnail;
    medium: Medium;
    small: Small;
}

export interface Attributes {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: Formats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl?: any;
    provider: string;
    provider_metadata?: any;
    createdAt: Date;
    updatedAt: Date;
}
export interface ICoverImage {
    data: {
        id: number;
        attributes: Attributes;
   }[]
}
export interface IBlogPostAttributes {
    Title: string;
    post: string;
    PublishDate: Date;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    coverImage: ICoverImage;
    subTitle: string;
}

const BlogPost: FC = () => {
    const params = useParams();
    const [blogPost, setBlogPost] = useState<IBlogPost>();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        console.log(blogPost);
        setTimeout(() => {
            setShow(true);
        }
            , 1);
        
    }, [blogPost]);
    useEffect(() => {
        if ((params.id?.length || 0) > 0) {
            fetch(`https://shark-app-bgi3b.ondigitalocean.app/api/blogs/${params.id}`).then(res => res.json()).then(data => {
                setBlogPost(data.data);
            }
            );
        }
    },[setBlogPost,params.id]);
    if ((params.id?.length || 0) > 0) {
      
        return <Transition show={show}  as="main" appear={true} enter={'duration-1000 transition-opacity ease-in-out'} enterFrom={'opacity-0'} enterTo={'opacity-100'} className="prose max-w-2xl mx-auto px-4 mt-16 relative ">
        <p onClick={()=>navigate('/blog')} className=" no-underline py-2 px-4 bg-black text-white cursor-pointer absolute -left-3 -top-12" >Back</p>           
            <h1 >{blogPost?.attributes.Title}</h1>
        <ReactMarkdown children={blogPost?.attributes.post || ''} />
        </Transition>
    }
    return <Navigate to={'/blog'}/>
}
export default BlogPost;
